<template>
  <v-container fluid>
    <PageHeaderSlot :showBottomLeftAndRightRow="true" :showBackButton="true" :isLoading="submitLoading">
    </PageHeaderSlot>
    <v-row class="mt-6">
      <v-col cols="12">
        <h3>{{ $validate.DataValid(id) ? '消息詳細' : '新增消息' }}</h3>
      </v-col>
      <v-col cols="12">
        <v-form ref="news-form" @submit.prevent="formSubmit">
          <v-card class="mb-8">
            <v-card-text>
              <v-row>
                <v-col md="6" cols="12">
                  <FormControl inputType="datePicker" :inputValue.sync="date" label="日期" :required="true" />
                </v-col>
                <v-col md="6" cols="12">
                  <FormControl inputType="timePicker" :inputValue.sync="time" label="時間" :required="true" />
                </v-col>
                <v-col cols="12">
                  <FormControl inputType="textarea" :inputValue.sync="content" label="內容" :required="true" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-row class="d-flex align-center flex-wrap">
            <v-btn depressed color="primary accent-4" class="white--text mr-6" type="submit" :loading="submitLoading">
              儲存
            </v-btn>

            <v-btn
              v-if="$validate.DataValid(this.id)"
              depressed
              color="error accent-4"
              class="white--text"
              :loading="submitLoading"
              @click="openDeleteDialog"
            >
              刪除
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row>

    <CustomDialog :dialog.sync="deleteDialog" v-on:close="removeNews"></CustomDialog>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormControl from '@/components/form/FormControl'
import CustomDialog from '@/components/CustomDialog.vue'

export default {
  name: 'NewsDetail',
  components: {
    FormControl,
    CustomDialog,
  },
  data: () => ({
    submitLoading: false,
    date: '',
    time: '',
    content: '',

    deleteDialog: false,
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    async getNewsDetail() {
      let newsError = false
      let message = '資料不存在'

      try {
        const payload = {
          id: parseInt(this.id),
          verify_token: this.getAdminToken(),
        }

        const result = await this.$XHR.api('cms_get_news_by_id', payload)
        this.$func.log('-----Get News Detail-----')
        this.$func.log(result)

        this.date = result.date
        this.time = result.time
        this.content = result.content

        this.$store.dispatch('toggleLoadingPage', false)
      } catch (error) {
        this.$func.log('-----Get News Fail-----')
        this.$func.log(error)

        if (error.data === 'admin verification fail') {
          this.forceLogout()
        } else if (error.data === 'no permission') {
          newsError = true
          message = '沒有權限'
        } else {
          newsError = true
        }
      }

      if (newsError) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message,
          type: 'error',
          refresh: false,
          redirect: 'NewsList',
        })
      }
    },
    async formSubmit() {
      if (!this.submitLoading) {
        this.submitLoading = true

        const isValid = this.$refs['news-form'].validate()

        if (!isValid) {
          this.submitLoading = false
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message: '請先填滿所有必填項目',
            type: 'error',
            refresh: false,
            redirect: '',
          })
          return
        }

        try {
          const payload = {
            data_type: 'news_data',
            status: 'active',
            data_status: 'active',
            verify_token: this.getAdminToken(),
            time: this.time,
            content: this.content,
            date: this.date,
          }

          let key = ''

          if (this.$validate.DataValid(this.id)) {
            payload.id = parseInt(this.id)
            key = 'cms_update_news'
          } else {
            key = 'cms_add_news'
          }

          const result = await this.$XHR.api(key, payload)
          this.$func.log('--- update news data ---')
          this.$func.log(result)

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'NewsList' },
          })
          this.setShowDialog(true)

          this.submitLoading = false
        } catch (error) {
          this.$func.log('--- update news data fail ---')
          this.$func.log(error)

          let msg = '儲存失敗'

          if (error.data === 'news verification fail') {
            msg = ''
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'news not found') {
            msg = '消息不存在'
          }

          this.setDialogMessage({
            message: msg,
            isError: false,
            returnLink: null,
          })
          this.setShowDialog(true)

          this.submitLoading = false
        }
      }
    },
    openDeleteDialog() {
      this.deleteDialog = true
    },
    async removeNews(action) {
      this.deleteDialog = false

      if (action) {
        if (!this.submitLoading) {
          this.submitLoading = true

          try {
            const payload = {
              id: parseInt(this.id),
              verify_token: this.getAdminToken(),
            }

            const result = await this.$XHR.api('cms_remove_news_by_id', payload)
            this.$func.log('--- remove news data ---')
            this.$func.log(result)

            this.setDialogMessage({
              message: '刪除成功',
              isError: false,
              returnLink: { name: 'NewsList' },
            })
            this.setShowDialog(true)

            this.submitLoading = false
          } catch (error) {
            this.$func.log('--- remove news data fail ---')
            this.$func.log(error)

            let msg = '刪除失敗'

            if (error.data === 'news verification fail') {
              msg = ''
              this.forceLogout()
            } else if (error.data === 'no permission') {
              msg = '沒有權限'
            } else if (error.data === 'news not found') {
              msg = '消息不存在'
            }

            this.setDialogMessage({
              message: msg,
              isError: false,
              returnLink: null,
            })
            this.setShowDialog(true)

            this.submitLoading = false
          }
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      if (!this.$validate.DataValid(this.id)) {
        this.$store.dispatch('toggleLoadingPage', false)
      } else {
        this.getNewsDetail()
      }
    }
  },
}
</script>
